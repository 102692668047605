import classnames from 'clsx';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import DisplayAd from 'src/common/components/ads/DisplayAd';
import FreeStarVideoAdContainer from 'src/common/components/ads/FreeStarVideoAdContainer';
import {
  BOTTOM_BANNER_ENABLED,
  FREESTAR_ENABLED,
  FREESTAR_PRIMIS_VIDEO_ENABLED,
} from 'src/common/constants/experiments/ads';
import useExperiment from 'src/common/hooks/useExperiment';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/config';
import { isSmall } from 'src/common/utils/breakpoints';
import { AD } from '../../constants/containerTypes';
import { AdSettingsProvider } from '../../contexts/AdSettingsContext';
import determineAdEligibility from '../../utils/ads/determineAdEligibility';
import keyMirror from '../../utils/keyMirror';
import LinkPresentation from './childPresentations/Link';
import NumberedLinkPresentation from './childPresentations/NumberedLink';
import css from './container-items.module.scss';
import Flow from './flow/Flow';
import GalleryLayout from './gallery/Gallery';
import ListLayout from './list/ListLayout';
import Matrix from './matrix/Matrix';
import ScheduleCard from './scheduleCard/ScheduleCard';
import LoadMoreItems from './shared/LoadMoreItems';
import Prompt from './shared/Prompt';
import getStylesCreator from './utils/getStylesCreator';

const layoutMap = {
  Gallery: GalleryLayout,
  Carousel: GalleryLayout,
  NumberedMatrix: NumberedLinkPresentation,
  LinkMatrix: LinkPresentation,
  Flow,
  Prompt,
  Matrix,
  TileMatrix: Matrix,
  ScheduleCard,
};

const reducedMarginItemsMap = keyMirror(
  'ItemCard',
  'EpisodeCard',
  'ScheduleCard',
);

function getLayout(layout) {
  return layoutMap[layout] || ListLayout;
}

const ContainerItems = ({
  items,
  itemsStyles,
  isFetching,
  breakpoint,
  routeProps,
  selectedContentId,
  className,
  isDarkMode: isDarkModeProp,
  isNowPlaying,
  isProfilePage,
  onItemClick,
  isInterestSelector,
  disableFirstContainerAd = false,
  guideItem,
  sponsorshipEnabled,
}) => {
  const isFreestarEnabled = useExperiment(FREESTAR_ENABLED);
  const isBottomBannerEnabled = useExperiment(BOTTOM_BANNER_ENABLED);
  const isFreestarPrimisVideoEnabled = useExperiment(
    FREESTAR_PRIMIS_VIDEO_ENABLED,
  );

  const canShowAds = useSelector(determineAdEligibility(guideItem));
  const isDarkMode = !!(isDarkModeProp || routeProps?.isDiscord);

  if (!items || !items.length) {
    return null;
  }

  const matchUrl = routeProps?.matchUrl;

  const shouldShowDisplayAd =
    !isBottomBannerEnabled &&
    !isSmall(breakpoint) &&
    canShowAds &&
    !disableFirstContainerAd;
  const shouldShowInContentVideoAd =
    canShowAds &&
    !isSmall(breakpoint) &&
    !disableFirstContainerAd &&
    !sponsorshipEnabled &&
    isFreestarEnabled &&
    isFreestarPrimisVideoEnabled;

  return (
    // data-darkmode attribute is used to apply changes to child components via css
    <div
      className={className}
      style={{ opacity: isFetching ? 0.5 : 1 }}
      data-darkmode={isDarkMode}
    >
      <AdSettingsProvider
        isAdEligible={canShowAds}
        isProfilePage={isProfilePage}
      >
        {items.map((item, i) => {
          const { containerNavigation, guideId } = item;
          const layout = item?.presentation?.layout;
          const Layout = getLayout(layout);
          const isAdContainer = item?.children?.[0]?.type === AD;
          const isFirstItem = i === 0;
          const getStyles = getStylesCreator(itemsStyles, item.style);
          const { divider: showDivider, backgroundColor } = getStyles();
          const isReducedMargin =
            reducedMarginItemsMap[item?.containerType] &&
            reducedMarginItemsMap[items[i + 1]?.containerType];
          const hasTitle = !!item?.title;
          const containerStyles = {
            ...(backgroundColor && { backgroundColor }),
          };

          return (
            <Fragment key={`${selectedContentId}-${i}-${guideId}`}>
              <div
                className={classnames(css.containerItem, {
                  [css.profilePage]: isProfilePage,
                  [css.adContainer]: isAdContainer,
                  [css.noSpace]: isInterestSelector,
                  [css.reducedMargin]: isReducedMargin,
                  [css.noTopPadding]: !hasTitle,
                })}
                style={containerStyles}
              >
                <Layout
                  matchUrl={matchUrl}
                  containerItem={item}
                  getStyles={getStyles}
                  breakpoint={breakpoint}
                  index={i}
                  isDarkMode={isDarkMode}
                  isInterestSelector={isInterestSelector}
                  isNowPlaying={isNowPlaying}
                  isDiscord={routeProps?.isDiscord}
                  enableHorizontalScroll={routeProps?.isFord}
                  selectedContentId={selectedContentId}
                  id={`container-${i}`}
                  onItemClick={onItemClick}
                />
                {containerNavigation && containerNavigation.destinationInfo && (
                  <LoadMoreItems
                    containerNavigation={containerNavigation}
                    index={i}
                    offset={item.children.length}
                    selectedContentId={selectedContentId}
                    containerItem={item}
                  />
                )}
                {showDivider && (
                  <div className={css.containerDivider}>
                    <hr />
                  </div>
                )}
              </div>
              {isFirstItem && shouldShowInContentVideoAd && (
                <FreeStarVideoAdContainer />
              )}
              {isFirstItem && shouldShowDisplayAd && (
                <DisplayAd
                  style={{ padding: '15px 0' }}
                  guideId={routeProps?.guideContext?.guideId}
                  matchUrl={matchUrl}
                  {...getDisplayAdAttributes(
                    gptSlotNames.browse_mid,
                    isFreestarEnabled,
                  )}
                />
              )}
            </Fragment>
          );
        })}
      </AdSettingsProvider>
    </div>
  );
};

ContainerItems.propTypes = {
  guideItem: PropTypes.object,
  items: PropTypes.array.isRequired,
  itemsStyles: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  breakpoint: PropTypes.number.isRequired,
  selectedContentId: PropTypes.string.isRequired,
  routeProps: PropTypes.object,
  className: PropTypes.string,
  isDarkMode: PropTypes.bool,
  isNowPlaying: PropTypes.bool,
  isProfilePage: PropTypes.bool,
  isInterestSelector: PropTypes.bool,
  onItemClick: PropTypes.func,
  disableFirstContainerAd: PropTypes.bool,
  sponsorshipEnabled: PropTypes.bool,
};

ContainerItems.defaultProps = {
  itemsStyles: {},
  className: '',
  isDarkMode: false,
  isNowPlaying: false,
  isProfilePage: false,
  onItemClick: () => {},
  sponsorshipEnabled: false,
};

export default ContainerItems;
